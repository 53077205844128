import isBoolean from 'lodash/isBoolean'
import AggregationType from 'models/AggregationType'
import {ResultType} from 'models/Report'
import {IRequisition, IRequisitionConcept, IRequisitionReport} from 'models/Requisition'
import {RequisitionStageType} from 'models/RequisitionStageType'

import {classificationLabelsMap} from 'constants/constants'

export const isPositiveOrIncreasedRiskCarrierResult = (report: IRequisitionReport) =>
  report &&
  report.report_data &&
  report.report_data.carrier &&
  (report.report_data.carrier.result === ResultType.increasedRisk ||
    report.report_data.carrier.result === ResultType.positive)

// Flag if a requisition has JSON report data from CROP
// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'req' implicitly has an 'any' type.
export const hasCropResults = req => {
  const hasReports = req.reports && req.reports.length && !!req.reports[req.reports.length - 1].report_data

  if (req.isCarrier && hasReports) {
    return !!req.reports[req.reports.length - 1].report_data.carrier
  }

  if ((req.isProactive || req.isNips) && hasReports) {
    return !!req.reports[req.reports.length - 1].report_data
  }

  return hasReports
}

export const isReqComplete = (req: IRequisition): boolean => req.active_stage_name === RequisitionStageType.COMPLETE

export const resultSummaryForCarrierAndProactive = (req: IRequisition): boolean =>
  isReqComplete(req) && req.results_released_to_patient && hasCropResults(req)

export const shouldShowResultsSummary = (req: IRequisition): boolean => {
  let result = false

  if (req.isCarrier || req.isProactive) {
    result = resultSummaryForCarrierAndProactive(req)
  } else if (req.isNips) {
    result = hasCropResults(req)
      ? isReqComplete(req) && req.results_released_to_patient && isBoolean(req.result_is_positive)
      : false
  }

  return result
}

export const countConceptGenes = (concepts: IRequisitionConcept[]): number =>
  concepts.reduce((acc: number, concept: IRequisitionConcept) => {
    if (concept.aggregation_type === AggregationType.CT_GROUP || concept.aggregation_type === AggregationType.CT_OPT) {
      acc += concept.total_genes_count
    } else if (concept.aggregation_type === AggregationType.CT_GENE) {
      acc += 1
    }
    return acc
  }, 0)

export const formatVariantName = (name: string): string => {
  if (name.includes(':')) {
    return name.split(':')[1]
  } else if (name.includes(',')) {
    return name.split(',')[1].trim()
  } else {
    return name
  }
}

const classificationsData = require('data/proactive-interactive-results/variants_classification.yml')

export const getClassificationDataFromValue = (value: string) => {
  const classificationObj = classificationLabelsMap.find(v => v.label === value || v.key === value)
  const classificationKey = classificationObj ? classificationObj.key : ''
  return classificationKey ? classificationsData[classificationKey] : {}
}

export const getClassificationLabelFromValue = (value: string): string => {
  return getClassificationDataFromValue(value).label || ''
}

export const getClassificationDescriptionFromValue = (value: string): string => {
  return getClassificationDataFromValue(value).description || ''
}
